export const internalEndpoints = {
  CLIENT: {
    ROOT: '/',
    VOTING: '/voting',
    HOSTING: '/hosting',
    ROOM: '/room',
    RESULTS: '/results',
    LIVE_RESULTS: '/live_results'
  }
}

export const external = {
  FOOBAR: 'https://demo.foobar.com/query/'
}
