import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import registerServiceWorker from 'registerServiceWorker'

import { internalEndpoints } from 'common/Endpoints'
import store from 'common/Store'

import Voting from 'pages/Voting'
import Login from 'pages/Login'
import Hosting from 'pages/Hosting'
import Room from 'pages/Room'
import Results from 'pages/Results'

import 'bootstrap/dist/css/bootstrap.css';

let { VOTING, ROOT, HOSTING, ROOM, RESULTS, LIVE_RESULTS } = internalEndpoints.CLIENT
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={ROOT}
          component={() => <Login />}
        />
        <Route
          exact
          path={VOTING}
          component={() => <Voting />}
        />
        <Route
          exact
          path={HOSTING}
          component={() => <Hosting/>}
        />
        <Route
          exact
          path={ROOM+'/:id'}
          component={() => <Room/>}
        />
        <Route
          exact
          path={RESULTS+'/:id'}
          component={() => <Results/>}
        />
        <Route
          exact
          path={LIVE_RESULTS}
          component={() => <Results/>}
        />
      </Switch>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
registerServiceWorker()
