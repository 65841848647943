import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDatabase, getKey } from 'api/Database'

import authProtected from 'pages/hoc/AuthProtected'
import asPage from 'pages/hoc/AsPage'

import CenteredContainer from 'components/CenteredContainer'
import VotingButton from '../components/VotingButton';

import { Layout, Typography, message, Input } from 'antd';

import styles from 'pages/styles/PageStyle'
import { css } from 'aphrodite'

const { Content } = Layout
const { Title, Text } = Typography
const { TextArea } = Input

class Voting extends Component {
  constructor (props) {
    super(props)
    this.key = getKey()
    this._onChangeNotes = this._onChangeNotes.bind(this)
    this.state = {
      notes: ""
    }
  }

  componentDidMount () {
    getDatabase().setVotesListener(this.key, this._onVotesDataUpdate)
    getDatabase().setIdeasListener(this.key, this._onIdeasDataUpdate)
  }

  componentWillUnmount () {
    getDatabase().clearVotesListener(this.key, this._onVotesDataUpdate)
    getDatabase().clearIdeasListener(this.key, this._onIdeasDataUpdate)
  }

  updateNotes = () => {
    if(!this.state.userVotes || !this.state.currentIdea) {
      return
    }
    
    let currentVote = this.state.userVotes.find(
      vote => vote.ideaId == this.state.currentIdea.id
    )

    if(currentVote) {
      this.setState({ notes: currentVote.notes })
    }  
  }
  
  _onVotesDataUpdate = async votes => {
    votes = votes.docs.map(doc => doc.data())

    let userVotes = votes.filter(
      vote => vote.userId == getDatabase().getUser().uid
    )

    await this.setState({votes, userVotes})
    this.updateNotes()
  }

  _onIdeasDataUpdate = async ideas => {
    // sort ideas and grab the latest one
    // we only vote on the latest idea
    ideas = ideas.docs
      .map(doc => { 
        let i = doc.data()
        i.id = doc.id
        return i
      })
      .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
      .filter(idea => !idea.deleted)

    let currentIdea = ideas.length > 0 ? ideas[0] : null

    await this.setState({ currentIdea: currentIdea })
    this.updateNotes()
  }

  _onVoteClick = (e) => {
    if(!e && !this._getCurrentVote()) {
      message.error("Could not save vote.")
      return
    }

    let user = getDatabase().getUser()
    let vote = {
      voteValue: e ? e.currentTarget.value : this._getCurrentVote().voteValue,
      ideaId: this.state.currentIdea.id,
      userId: user.uid,
      isAnonymousUser: user.isAnonymous,
    }

    if(this.state.notes) {
      vote.notes = this.state.notes
    }

    let guest = getDatabase().getGuest()
      .then(guest => {
        if(guest) {
          vote.guestName = guest.name
        }
        else {
          vote.photoUrl = user.photoURL
        }

        return getDatabase().saveVote(vote)
          .then(() => {

            message.success('Vote saved.');
          })
      })
  }

  _getIdeaName = () => {
    return this.state.currentIdea
  }

  _getVotingValues = () => {
    // 0 through 10, skipping 5
    return [0,1,2,3,4,6,7,8,9,10]
  }

  _getCurrentVote = () => {
    if(!this.state.currentIdea) {
      return
    }

    let userVotes = this.state.userVotes || []
    let currentVote = userVotes.find(
      vote => vote.ideaId == this.state.currentIdea.id
    )

    return currentVote
  }

  _getCurrentVoteString = () => {
    let currentVote = this._getCurrentVote()
    return !!currentVote ? currentVote.voteValue : "You have not voted yet."
  }

  _renderVotingButton = (v) => {
    return (<VotingButton onClick={this._onVoteClick} voteValue={v} key={v} />)
  }
  
  _onChangeNotes = (e) => {
    this.setState({ notes: e.currentTarget.value })
  }

  render () {
    let currentVote = this._getCurrentVote()
    let currentVoteString = this._getCurrentVoteString()

    return (
      <Layout>
        <Content className={css(styles.content)}>
          { (!this.state || !this.state.currentIdea) &&
              <div>Waiting for inspiration to strike.</div>
          }
          { this.state && this.state.currentIdea &&
            <div style={{display:'inline'}}>
              <Title level={2}>{this.state.currentIdea.name}</Title>
              <div style={{marginBottom: '30px'}}>{this.state.currentIdea.description}</div>
                <Title level={3} style={{marginBottom: '30px'}}>
                  Your vote: 
                  <div style={{display:'inline', marginLeft:'10px', marginBottom: '30px'}}> 
                    { currentVoteString }
                  </div>
                </Title>
              <CenteredContainer>
                  { this._getVotingValues().map( v => this._renderVotingButton(v) ) }        
              </CenteredContainer>
              <div>
                <Title level={3} style={{marginBottom: '30px'}}>
                  Notes:
                </Title>
                <TextArea
                  placeholder="I like this idea because... Would this still work if...?"
                  onChange={this._onChangeNotes}
                  value={this.state.notes}
                  rows={5}
                  style={{marginTop: '15px'}}
                  onBlur={()=> { if(currentVote) { this._onVoteClick() } }}
                />
              </div>
            </div>
          }
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.demo
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default authProtected(
  asPage(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Voting)
  )
)
