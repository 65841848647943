import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDatabase, getKey } from 'api/Database'

import { 
  Layout, 
  Input, 
  Button, 
  Typography, 
  Popconfirm, 
  message,
  Table,
  Divider
} from 'antd';

import { withRouter, Link } from 'react-router-dom'

import authProtected from 'pages/hoc/AuthProtected'
import asPage from 'pages/hoc/AsPage'

import styles from 'pages/styles/PageStyle'
import { css } from 'aphrodite'

import { internalEndpoints } from 'common/Endpoints'

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

class Room extends Component {
  constructor (props) {
    super(props)
    this.key = getKey()
    this.state = {
      ideas: []
    }
  }

  componentDidMount () {
    getDatabase().setRoomsListener(this.key, this._onRoomsDataUpdate)
    getDatabase().setIdeasListener(this.key, this._onIdeasDataUpdate)
    getDatabase().setVotesListener(this.key, this._onVotesDataUpdate)
  }

  componentWillUnmount () {
    getDatabase().clearRoomsListener(this.key, this._onRoomsDataUpdate)
    getDatabase().clearIdeasListener(this.key, this._onIdeasDataUpdate)
    getDatabase().clearVotesListener(this.key, this._onVotesDataUpdate)
  }

  _onRoomsDataUpdate = rooms => {
    rooms = rooms.docs.map(doc => { 
      let r = doc.data()
      r.id = doc.id
      return r
    })
    
    let room = rooms.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
      .find(room => {
        return !room.deleted && room.id == this.props.match.params.id
      })

    this.setState({ room: room })
  }

  _onIdeasDataUpdate = ideas => {
    ideas = ideas.docs
      .map(doc => { 
        let i = doc.data()
        i.id = doc.id
        return i
      })
      .filter(idea => 
        idea.roomId == this.props.match.params.id &&
        !idea.deleted
      )
      .sort((a,b) => b.createdAt.toDate() - a.createdAt.toDate())

    this.setState({ ideas })
  }

  _onVotesDataUpdate = votes => {
    votes = votes.docs.map(doc => doc.data())
    this.setState({votes})
  }

  deleteIdea = (ideaId) => {
    getDatabase().deleteIdea({id: ideaId})
      .then(() => {
        message.success('Idea successfully archived.');
      })
  }

  _onUpdateRoomName = (name) => {
    let room = {
      id: this.state.room.id,
      name: name
    }
    getDatabase().saveRoom(room)
      .then(() => {
        message.success('Room updated successfully.');
      })
  }

  _getIdeaTableColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '40px'
      },
      {
        title: '',
        dataIndex: 'links',
        key: 'links',
        render: idea => <div>
          <div>
            <Link to={internalEndpoints.CLIENT.RESULTS + '/' + idea.id}>
              View
            </Link>
          </div>
          <div>
            <Popconfirm
              title="Are you sure archive this idea?"
              onConfirm={() => { this.deleteIdea(idea.id)}}
              onCancel={this._onCancelDeleteIdea}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Archive</a>
            </Popconfirm>
          </div>
        </div>
      },
    ]
  }

  _makeIdeaTableEntry = (idea) => {
    return {
      key: getKey(),
      name: idea.name,
      links: idea,
      createdAt: idea.createdAt.toDate().toLocaleDateString("en-US"),
    }
  }

  _onConfirmDeleteIdea = (e) => {
    return
  }

  _onCancelDeleteIdea = (e) => {
    return
  }

  _onChangeIdeaName = (e) => {
    this.setState({ideaName: e.target.value})
  }

  _onChangeIdeaDescription = (e) => {
    this.setState({ideaDescription: e.target.value})
  }

  _onClickCreateIdea = (e) => {
    let idea = {
      name: this.state.ideaName,
      description: this.state.ideaDescription,
      hostUserId: getDatabase().getUser().uid,
      roomId: this.state.room.id
    }

    getDatabase().saveIdea(idea)
      .then(()=> { 
        this.setState({
          ideaName: null,
          ideaDescription: null,
        }, () => {
          document.getElementById('idea-name-input').value = ""
          document.getElementById('idea-description-input').value = ""
        })
      })
    
  }
 
  render () {
    return (
        <Layout>
          { this.state.room &&
          <Content className={css(styles.content)}>
            <Title level={2}>{this.state.room.name}</Title>
            <div>
              <Title level={3}>Create Idea</Title>
              <div>
                <Input
                  placeholder="idea name"
                  onChange={this._onChangeIdeaName}
                  style={{maxWidth: '300px', minWidth: '50px', marginBottom: '10px'}}
                  id="idea-name-input"
                />
              </div>
              <div>
                <TextArea
                  placeholder="idea description"
                  onChange={this._onChangeIdeaDescription}
                  style={{maxWidth: '300px', minWidth: '50px', marginBottom: '10px'}}
                  id="idea-description-input"
                  autosize={{ minRows: 2 }}
                />
              </div>
              <Button
                type='primary'
                onClick={this._onClickCreateIdea}
                className="btn btn-primary btn-block btn-dark"
                style={{height: '40px', maxWidth: '300px', minWidth: '50px', marginBottom: '40px'}}
                disabled={!this.state.ideaName || !this.state.ideaDescription}
              >
                Create
              </Button>
              <Title level={3}>Ideas</Title>
              <div>
                <Table
                  columns={this._getIdeaTableColumns()}
                  dataSource={this.state.ideas.map(idea => this._makeIdeaTableEntry(idea))}
                />
              </div>
            </div>
          </Content>
          }
        </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.demo
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default authProtected(
  withRouter(
    asPage(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Room)
    )
  )
)
