// Old style so we can use with node
const TABLES = {
  USERS: 'users',
  VOTES: 'votes',
  IDEAS: 'ideas',
  ROOMS: 'rooms',
  GUESTS: 'guests',
  ADMINS: 'admins'
}

module.exports = TABLES
