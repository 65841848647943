// Old style so we can use with node
const CONFIG = {
    apiKey: "AIzaSyAUNbAWzsedb39Nx5CwyL0mMf57mosoOJI",
    authDomain: "ideation-2574f.firebaseapp.com",
    databaseURL: "https://ideation-2574f.firebaseio.com",
    projectId: "ideation-2574f",
    storageBucket: "ideation-2574f.appspot.com",
    messagingSenderId: "529853212142"
}

module.exports = CONFIG
