import { StyleSheet } from 'aphrodite'
import { globalStyles } from 'common/Styles'

export default StyleSheet.create({
  ...globalStyles,
  content: {
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: 'white',
    width: '100%',
    paddingTop: '40px'
  },
  layout: {
    backgroundColor: 'white',
  },
  sider: {
    overflow: 'auto',
    height: '100vh',
    paddingTop: '100px',
    backgroundColor: '#000000'
  },
  centeredRow: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  }
})
