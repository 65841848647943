import React, { PureComponent } from 'react'
import { Layout, Menu, Icon } from 'antd'

import HeaderBar from 'components/HeaderBar'
import { getDatabase, getKey } from 'api/Database'

import styles from 'pages/styles/PageStyle'

import { css } from 'aphrodite'

const {
  Sider, Content,
} = Layout;

export default WrappedComponent => {
  class Wrapper extends PureComponent {
    _renderSider = () => {
      if(1) return
      
      let user = getDatabase().getUser()
      if (!user) {
        return
      }
      return <Sider className={css(styles.sider)}>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
          <Menu.Item key="1">
            <Icon type="user" />
            <span className="nav-text">nav 1</span>
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="video-camera" />
            <span className="nav-text">nav 2</span>
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="upload" />
            <span className="nav-text">nav 3</span>
          </Menu.Item>
        </Menu>
      </Sider>
    }

    _renderHeader = () => {
      let user = getDatabase().getUser()
      if(!user) {
        return
      }
      return <HeaderBar/>
    }
    render () {
      return <Layout className={css(styles.layout)}>
        { this._renderSider() }
        <Layout>
          { this._renderHeader()}
          <Content className={css(styles.content)}>
            <WrappedComponent {...this.props} />
          </Content>
        </Layout>
      </Layout>
    }
  }

  return Wrapper
}
