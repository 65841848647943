import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDatabase, getKey } from 'api/Database'

import { 
  Layout, 
  Input, 
  Button, 
  Typography, 
  Popconfirm, 
  message,
  Table,
  Divider
} from 'antd';

import { withRouter, Link } from 'react-router-dom'

import { internalEndpoints } from 'common/Endpoints'

import authProtected from 'pages/hoc/AuthProtected'
import asPage from 'pages/hoc/AsPage'

import styles from 'pages/styles/PageStyle'
import { css } from 'aphrodite'

const { Content } = Layout;
const { Title, Text } = Typography;

class Hosting extends Component {
  constructor (props) {
    super(props)
    this.key = getKey()
    this.state = {}
  }

  componentDidMount () {
    getDatabase().setRoomsListener(this.key, this._onRoomsDataUpdate)
  }

  componentWillUnmount () {
    getDatabase().clearRoomsListener(this.key, this._onRoomsDataUpdate)
  }

  _onRoomsDataUpdate = rooms => {
    rooms = rooms.docs.map(doc => {
      let r = doc.data()
      r.id = doc.id
      return r
    })

    rooms = rooms
        .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        .filter(room => {
          return !room.deleted
        })

    this.setState({ rooms: rooms })
  }

  deleteRoom = (room) => {
    getDatabase().deleteRoom({id: room.id})
      .then(() => {
        message.success('Room successfully archived.');
      })
  }

  viewRoom = (room) => {
    this.props.history.push({
      pathname: internalEndpoints.CLIENT.ROOM + room.id,
    })
  }

  _onChangeRoomName = (e) => {
    this.setState({roomName: e.target.value})
  }

  _onClickCreateRoom = (e) => {
    let room = {
      name: this.state.roomName
    }

    getDatabase().saveRoom(room)
      .then(()=> { 
        this.setState({roomName: null}, () => {
          document.getElementById('room-name-input').value = ""
        })
      })
  }

  _getRoomTableColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: '',
        dataIndex: 'links',
        key: 'links',
        render: room => <div>
          <div>
            <Link to={internalEndpoints.CLIENT.ROOM + '/' + room.id}>
              View
            </Link>
          </div>
          <div>
            <Popconfirm
              title="Are you sure you want to archive this room?"
              onConfirm={() => this.deleteRoom(room)}
              onCancel={this._onCancelDeleteRoom}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Archive</a>
            </Popconfirm>
          </div>
        </div>
      },
    ]
  }

  _makeRoomTableEntry = (room) => {
    return {
      key: getKey(),
      name: room.name,
      createdAt: room.createdAt.toDate().toLocaleDateString("en-US"),
      links: room
    }
  }
 
  _onConfirmDeleteRoom = (e) => {
    return
  }

  _onCancelDeleteRoom = (e) => {
    return
    // message.success('Room successfully archived.');
  }

  render () {
    return (
      <Layout className={ css(styles.layout)}>
        <Title level={2}>Hosting</Title>
        <Title level={3}>Create Room</Title>

        <Input
          placeholder="room name"
          onChange={this._onChangeRoomName}
          style={{maxWidth: '300px', minWidth: '50px', marginBottom: '10px'}}
          id="room-name-input"
        />
        <Button
          type='primary'
          onClick={this._onClickCreateRoom}
          className="btn btn-primary btn-block btn-dark"
          style={{height: '40px', maxWidth: '300px', minWidth: '50px', marginBottom: '40px'}}
          disabled={!this.state.roomName}
        >
          Create
        </Button>
        { this.state.rooms &&
          <div>
            <Table 
              columns={this._getRoomTableColumns()} 
              dataSource={this.state.rooms.map(room => this._makeRoomTableEntry(room))}
            />
          </div>
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.demo
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default authProtected(
  withRouter(
    asPage(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Hosting)
    )
  )
)
