import React, { Component } from 'react'
import {
  Button,
  Input,
  Row
} from 'antd'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getDatabase, getKey } from 'api/Database'
import { internalEndpoints } from 'common/Endpoints'

import asPage from 'pages/hoc/AsPage'

import CenteredContainer from 'components/CenteredContainer'

import styles from 'pages/styles/PageStyle'
import { css } from 'aphrodite'

const LOGIN_TIMEOUT = 2000

class Login extends Component {
  constructor (props) {
    super(props)
    this.key = getKey()
    this._loginTimeout = null
    this.state = {
      loggingIn: true
    }
  }

  componentDidMount () {
    this._loginTimeout = setTimeout(() => this.setState({ loggingIn: false }), LOGIN_TIMEOUT)
    this._redirectIfLoggedIn()
  }

  componentWillUnmount () {
    if (this._loginTimeout !== null) {
      clearTimeout(this._loginTimeout)
    }
  }

  componentDidUpdate () {
    this._redirectIfLoggedIn()
  }

  _redirectIfLoggedIn = () => {
    if (this.props.user !== null) {
      this.props.history.push(internalEndpoints.CLIENT.VOTING)
    } else {
      if (this.state.loggingIn) {
        this.setState({ loggingIn: false })
      }
    }
  }

  _login = (opts = {}) => {
    let db = getDatabase()

    if (!this.state.loggingIn) {
      this.setState({
        loggingIn: true
      }, () => opts.loginAsGuest ? db.loginGuest(opts.guestName) : db.loginEmail())
    }
  }

  _onClickLoginEmail = () => {
    this._login()
  }

  _onClickLoginGuest = () => {
    this._login({ 
      loginAsGuest: true, 
      guestName: this.state.guestName
    })
  }

  _onChangeGuestName = (e) => {
    this.setState({guestName: e.target.value})
  }

  render () {
    return (
      <CenteredContainer>
        <div className={css(styles.centeredRow)}>
          <img src="/img/psl.gif" width="40%"/>
        </div>
        <div className={css(styles.centeredRow)}>
          <Input
            placeholder="Guest Name"
            onChange={this._onChangeGuestName}
            style={{maxWidth: '300px', minWidth: '50px', width: '100%', marginBottom: '10px'}}
          />
        </div>
        <div className={css(styles.centeredRow)}>
          <Button
            type='primary'
            onClick={this._onClickLoginGuest}
            className="btn btn-primary btn-block btn-dark"
            style={{height: '40px', maxWidth: '300px', minWidth: '50px', marginBottom: '40px'}}
            disabled={!this.state.guestName}
          >
            Continue as Guest
          </Button>
        </div>
        <div className={css(styles.centeredRow)}>
          <Button
            type='primary'
            loading={this.state.loggingIn}
            onClick={this._onClickLoginEmail}
            className="btn btn-primary btn-block btn-dark"
            style={{height: '40px', maxWidth: '300px', minWidth: '50px', marginBottom: '10px'}}
          >
            Continue as PSL
          </Button>
        </div>
      </CenteredContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.user
  }
}

export default withRouter(
  asPage(
    connect(
      mapStateToProps,
      null
    )(Login)
  )
)
