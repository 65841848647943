import React, { Component } from 'react'
import {
  Button,
} from 'antd'

export default class VotingButton extends Component {
  render () {
    return (
      <Button 
        type='primary'
        shape='circle'
        size='large'
        onClick={this.props.onClick}
        value={this.props.voteValue}
        // className="btn btn-default btn-dark"
        style={{
          backgroundColor: '#000', 
          height: '80px', 
          width: '80px', 
          marginBottom: '20px', 
          marginRight: '20px', 
          borderRadius: '50%',
          borderWidth: '0px',
        }}
      >
        {this.props.voteValue}
      </Button>
    )
  }
}
