import EventEmitter from 'eventemitter3'
import firebase from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import sha1 from 'sha1'

import { changeUser, logOutUser } from 'actions/User'

import FirebaseConfig from 'api/FirebaseConfig'
import FirebaseTables from 'api/FirebaseTables'

import store from 'common/Store'
import { assert } from '@firebase/util';

export const getKey = () => {
  let currentDate = (new Date()).valueOf().toString()
  let random = Math.random().toString()
  return sha1(currentDate + random)
}

/**
 * An EventEmitter-based database that wraps a connection to the old-school
 * firebase datastore
 */
class Database extends EventEmitter {
  constructor () {
    super()
    this.emitEvent = {}
    this.unsubscribeEvents = {}

    if (!firebase.apps.length) {
      firebase.initializeApp(FirebaseConfig)
    }
    firebase.auth().onAuthStateChanged(this._onSetUser)

    this.firestore = firebase.firestore()

    this._onSetUser(this.getUser())
  }

  _onSetUser = async user => {
    if (!user) {
      store.dispatch(logOutUser(user))
    } else {
      const authorizedEmails = [
        'psl.com',
        'pioneersquarelabs.com'
      ]
      if(!user.isAnonymous && !user.email) {
        // something went wrong. log out
        store.dispatch(logOutUser(user))
        return
      }
      
      let valid = authorizedEmails
        .map(e => user.isAnonymous || user.email.endsWith(e))
        .reduce((acc, val) => acc || val, false)
      if (valid) {
        let guest = await this.getGuest(user.uid)
          .then(guest => {
            if(guest) {
              user.name = guest.name
              user.guest = guest
            }
            store.dispatch(changeUser(user))
          })
      } else {
        store.dispatch(logOutUser(user))
      }
    }
  }

  loginEmail () {
    let provider = new firebase.auth.GoogleAuthProvider()
    let self = this

    firebase.auth()
      .signInWithPopup(provider)
      .catch(error => {
        console.log(error)
        self.emit(error)
      })
  }

  loginGuest (name) {
    assert(!!name, "Guest name can't be empty")
  
    let self = this

    firebase.auth()
      .signInAnonymously()
      .catch(function(error) {
        console.log(error)
        self.emit(error)
      })
      .then((result) => {
        this.saveGuestName(name)
      })
    
  }

  async logout () {
    await firebase.auth().signOut()
  }

  getUser () {
    return firebase.auth().currentUser
  }
  
  getCollection (name) {
    return this.firestore.collection(name)
  }

  getUserData = async () => {
    let doc = await this.firestore
      .collection('users')
      .doc(this.getUser().uid)
      .get()
    return doc.data()
  }

  // Set listeners
  setVotesListener (key, onUpdate) {
    this.setDataListener(key, FirebaseTables.VOTES, onUpdate)
  }

  setIdeasListener (key, onUpdate) {
    this.setDataListener(key, FirebaseTables.IDEAS, onUpdate)
  }

  setAdminsListener (key, onUpdate) {
    this.setDataListener(key, FirebaseTables.ADMINS, onUpdate)
  }

  setRoomsListener (key, onUpdate) {
    this.setDataListener(key, FirebaseTables.ROOMS, onUpdate)
  }

  // Clear listeners
  clearVotesListener (key, onUpdate) {
    this.clearDataListener(key, FirebaseTables.VOTES, onUpdate)
  }

  clearIdeasListener (key, onUpdate) {
    this.clearDataListener(key, FirebaseTables.IDEAS, onUpdate)
  }

  clearAdminsListener (key, onUpdate) {
    this.clearDataListener(key, FirebaseTables.ADMINS, onUpdate)
  }

  clearRoomsListener (key, onUpdate) {
    this.clearDataListener(key, FirebaseTables.ROOMS, onUpdate)
  }

  setDataListener (key, ref, onUpdate) {
    let k = key + ref
    this.addListener(k, onUpdate)

    this.emitEvent[k] = (doc) => this.emit(k, doc)
    this.unsubscribeEvents[k] = this.firestore.collection(ref).onSnapshot(this.emitEvent[k])
  }

  clearDataListener (key, ref, onUpdate) {
    let k = key + ref
    this.removeListener(k, onUpdate)
    delete this.emitEvent[k]
    this.unsubscribeEvents[k]()
    delete this.unsubscribeEvents[k]
  }

  saveRoom(room) {
    assert(!!room.name, "Room must have a name")

    let collectionRef = this.firestore.collection(FirebaseTables.ROOMS)
    
    let now = new Date()

    if(room.id) {
      room.updatedAt = now
      return collectionRef.doc(room.id).update(room)
    }

    room.createdAt = now
    return collectionRef.add(room) // not enforcing uniqueness by name
  }

  saveIdea(idea) {
    assert(!!idea.name, "Idea must have a name")
    assert(!!idea.description, "Idea must have a description")

    let collectionRef = this.firestore.collection(FirebaseTables.IDEAS)
    
    let now = new Date()

    if(idea.id) {
      idea.updatedAt = now
      return collectionRef.doc(idea.id).update(idea)
    }
    
    idea.createdAt = now
    return collectionRef.add(idea) // not enforcing uniqueness by name
  }

  async saveGuestName(name) {
    assert(!!name, "Guest must have a name")

    let guest = {
      name: name,
      userId: this.getUser().uid
    }
    
    let collectionRef = this.firestore.collection(FirebaseTables.GUESTS)
    let query = await collectionRef.where('userId', '==', guest.userId).get()

    if (query.empty) {
      return collectionRef.add(guest)
    } else {
      return collectionRef.doc(query.docs[0].id).set(guest)
    }
  }

  // Saves a unique entry e.g. a tag in a table of our choice
  async saveVote(vote) {
    let collectionRef = this.firestore.collection(FirebaseTables.VOTES)

    let query = await collectionRef
      .where('userId', '==', vote.userId)
      .where('ideaId', '==', vote.ideaId)
      .get()

    let now = new Date()

    if (query.empty) {
      vote.updatedAt = now
      vote.createdAt = now

      return collectionRef.add(vote)
    } else {
      vote.updatedAt = now
      assert(query.docs.length === 1, "found multiple votes for user/idea pair")
      return collectionRef.doc(query.docs[0].id).set(vote)
    }
  }

  async getGuest() {
    let user = this.getUser()
    let collectionRef = this.firestore.collection(FirebaseTables.GUESTS)
    
    let query = await collectionRef.where('userId', '==', user.uid).get()

    if (query.empty) {
      return null
    } else {
      return query.docs[0].data()
    }
  }

  async deleteRoom(room) {
    assert(!!room.id, "Room must have an id for a delete")
    await this.deleteUniqueItem(room.id, FirebaseTables.ROOMS)
  }

  async deleteIdea(idea) {
    assert(!!idea.id, "Room must have an id for a delete")
    await this.deleteUniqueItem(idea.id, FirebaseTables.IDEAS)
  }

  async deleteUniqueItem (id, collection) {
    await this.firestore.collection(collection).doc(id).update({ deleted: true })
  }
}

// Create database eagerly
var INSTANCE = new Database()
export const getDatabase = () => INSTANCE
