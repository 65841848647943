import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite'
import {
  Avatar,
  Col,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Row
} from 'antd'

import { getDatabase, getKey } from 'api/Database'
import styles from 'components/styles/HeaderBarStyle'
import { internalEndpoints } from 'common/Endpoints'

import { withRouter } from 'react-router-dom'

const routeNames = {
  [internalEndpoints.CLIENT.HOSTING]: 'Hosting',
  [internalEndpoints.CLIENT.VOTING]: 'Voting',
  [internalEndpoints.CLIENT.LIVE_RESULTS]: 'Live Results'
}

const routes = [
  internalEndpoints.CLIENT.HOSTING, 
  internalEndpoints.CLIENT.VOTING, 
  internalEndpoints.CLIENT.LIVE_RESULTS
]

class HeaderBar extends Component {

  constructor (props) {
    super(props)
    this.key = getKey()
    this.state = {}
  }

  _onLogoutClick = () => {
    getDatabase().logout()
  }

  componentDidMount () {
    getDatabase().setAdminsListener(this.key, this._onAdminsDataUpdate)
  }

  componentWillUnmount () {
    getDatabase().clearAdminsListener(this.key, this._onAdminsDataUpdate)
  }

  _onAdminsDataUpdate = admins => {
    let user = getDatabase().getUser()

    if(!user) return;

    admins = admins.docs.map(doc => doc.data())

    let isUserAdmin = admins.filter(vote => vote.userId == user.uid).length > 0

    this.setState({isUserAdmin})
  }

  _redirectTo(route) {
    this.props.history.push(route)
  }

  shouldShowLink(route) {
    return !this.props.user.isAnonymous && this.props.location.pathname != route
  }

  renderPageLink(route) {
    if(this.shouldShowLink(route)) {
      return <Menu.Item>
        <a key={getKey()} onClick={() => this._redirectTo(route)}>{routeNames[route]}</a>
      </Menu.Item>
    }
  }

  render () {
    let { user } = this.props
    let login = null
    if (user !== null) {
      const logoutMenu = (
        <Menu>
          { routes.map((route) => this.renderPageLink(route)) }
          <Menu.Item>
            <a onClick={this._onLogoutClick} >Log out</a>
          </Menu.Item>
        </Menu>
      )
      login = (
        <Dropdown overlay={logoutMenu} style={{paddingRight: '30px'}}>
          <div style={{
            height: "100px", 
            display: 'inline-flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            verticalAlign: 'middle',
          }}>
            { 
              user.isAnonymous ? 
                <Avatar key={getKey()} size={45} style={{ marginRight: 6 }} >
                  {user.name.split(" ")[0]}
                </Avatar> :
                <Avatar key={getKey()} src={user.photoURL} style={{ marginRight: 6 }} />
            }
            <a className='ant-dropdown-link'>
              {user.DisplayName} <Icon type='down'/>
            </a>
          </div>
        </Dropdown>
      )
    }

    return (
      <Layout.Header className={css(styles.navHeader)}>
        <Row type='flex'>
          <Col key={getKey()} span={4} offset={0}>
            <img src="/img/psl.gif" width="100px"/>
          </Col>
          <Col key={getKey()} span={2} offset={16} height={100}>
            {login}
          </Col>
        </Row>
      </Layout.Header>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.user
  }
}

export default withRouter(connect(mapStateToProps, null)(HeaderBar))
