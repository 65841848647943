import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDatabase, getKey } from 'api/Database'

import { 
  Layout,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Popconfirm,
  Avatar,
  message

} from 'antd';

import { withRouter, Link } from 'react-router-dom'

import authProtected from 'pages/hoc/AuthProtected'
import asPage from 'pages/hoc/AsPage'

import styles from 'pages/styles/PageStyle'
import { css } from 'aphrodite'

import { internalEndpoints } from 'common/Endpoints'

import CenteredContainer from 'components/CenteredContainer'

const { Sider, Content } = Layout;

const { Title, Text } = Typography;

class Results extends Component {
  constructor (props) {
    super(props)
    this.key = getKey()
    this.state = {
      passedIdeaId: this.props.match && this.props.match.params.id ? this.props.match.params.id : null
    }
  }

  componentDidMount () {
    getDatabase().setIdeasListener(this.key, this._onIdeasDataUpdate)
    getDatabase().setVotesListener(this.key, this._onVotesDataUpdate)

    let user = getDatabase().getUser()
    this.setState({user})
  }

  componentWillUnmount () {
    getDatabase().clearIdeasListener(this.key, this._onIdeasDataUpdate)
    getDatabase().clearVotesListener(this.key, this._onVotesDataUpdate)
  }

  _onIdeasDataUpdate = ideas => {

    let idea = null

    ideas = ideas.docs
      .map(doc => { 
        let i = doc.data()
        i.id = doc.id
        return i
      })
    
    if(this.state.passedIdeaId) {
      idea = ideas.find(idea => 
        idea.id == this.state.passedIdeaId && 
        !idea.deleted
      )
    }
    else {
      // sort ideas and grab the latest one
      // we only vote on the latest idea
      ideas = ideas
        .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        .filter(idea => !idea.deleted)

      idea = ideas.length > 0 ? ideas[0] : null
    }

    this.setState({ idea })
  }

  _onVotesDataUpdate = votes => {
    
    let ideaId = this.state.passedIdeaId || this.state.idea.id
    
    votes = votes.docs
      .map(doc => doc.data())
      .filter(vote => vote.ideaId == ideaId)

    this.setState({votes})
  }

  _getVotingStats = () => {
    if(!this.state.votes || this.state.votes.length == 0) {
      return [
        { name: 'Vote Count', value: 0 }
      ]
    }

    let voteValues = this.state.votes.map(vote => parseFloat(vote.voteValue))
    let n = voteValues.length
    
    let sum = (vals) => vals.reduce((a,b) => a+b)

    return [
      { name: 'Min', value: Math.min(...voteValues) },
      { name: 'Max', value: Math.max(...voteValues) },
      { name: 'Average', value: (sum(voteValues) * 1.0 / n).toFixed(2) }
    ]
  }

  _getVotingValues = () => {
    // 0 through 10, skipping 5
    return [0,1,2,3,4,6,7,8,9,10]
  }

  _getVotesWithValue = val => {
    if(!this.state.votes) return [];
    return this.state.votes.filter(vote => vote.voteValue == val) || []
  }

  _renderStat = stat => {
    return (
      <Col span={4} style={{textAlign: 'center'}}>
        <Text strong>{stat.name}: {stat.value}</Text>
      </Col>
    )
  }

  _renderVote = vote => {
    let avatarStyle = {
      backgroundColor: '#000000',
    }
    return <div style={{ marginBottom: '15px'}}>
      { vote.isAnonymousUser ?
          <Avatar key={getKey()} size={45} style={avatarStyle}>
            {vote.guestName.split(" ")[0]}
          </Avatar> :
          <Avatar key={getKey()} size={45} style={avatarStyle} src={vote.photoUrl}/>
      }
    </div>
  }

  _renderVoteColumn = (val) => {
    return (
      <Col span={2} style={{textAlign: 'center', marginRight: '3px'}}>
        <Avatar
          size={60}
          style={{
            backgroundColor: '#000000', 
            marginBottom: '30px', 
            backgroundColor: '#000000',
          }}
        >
          {val}
        </Avatar>
        { this._getVotesWithValue(val).map(vote => this._renderVote(vote)) }
      </Col>
    )
  }

  render () {
    return (
      <Layout className={ css(styles.layout)}>
        <CenteredContainer>
          { this.state.idea &&
              <div style={{marginBottom: '15px'}}>
                <Title>{this.state.idea.name}</Title>
                <div>{this.state.idea.description}</div>
              </div>
          }
        </CenteredContainer>
        <Row type="flex" justify="center" style={{marginBottom: '60px'}}>
          { this._getVotingStats().map(stat => this._renderStat(stat)) }
        </Row>
        <Row type="flex" justify="center">
          <div style={{
            textAlign: 'center', 
            width: '1000px', 
            display: 'flex', 
            justifyContent: 'center'
          }}>
            { this._getVotingValues().map(val => this._renderVoteColumn(val)) }
          </div>
        </Row>              
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.demo
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default authProtected(
  withRouter(
    asPage(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Results)
    )
  )
)
